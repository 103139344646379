<template>
    <div class="info">
        <b-card>
            <h3>Total of Coupons Redeemed: {{ total }}</h3>
        </b-card>
        <b-card>
            <h3>Total discount: {{ discount }}</h3>
        </b-card>
        <b-card>
            <h3>Net Income Cupons: {{ netIncome }}</h3>
        </b-card>
        <b-card>
            <h3>Income Cupons: {{ income }}</h3>
        </b-card>
    </div>
</template>

<script>
import {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
} from "bootstrap-vue";
import logo from "@/assets/images/myclinic-rec-2.png"

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        BAvatar,
    },
    props: {
        datarda: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    watch: {
        datarda() {
            if (!this.datarda) return
            this.total = this.datarda.CAMPAIGN_CUPONS['30'].CUPONS_PAGINATE?.total
            this.discount = this.datarda.CAMPAIGN_CUPONS['30'].TOTAL_DISCOUNT
            this.income = this.datarda.TOTALS.income_cupons
            this.netIncome = this.datarda.TOTALS.net_income_cupons
        }
    },
    data() {
        return {
            total: 0,
            income: 0,
            netIncome: 0,
            discount: 0,
            logo: logo
        };
    },
};
</script>
<style lang="scss" scoped>
.info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding-top: 2%;
}
</style>
